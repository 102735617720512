<!--
 * @Author: hwu
 * @Date: 2020-10-23 17:14:51
 * @Description: 菜谱页面 -> 搜索页面弹窗
 * @LastEditTime: 2021-02-08 20:53:00
-->

<template>
  <div class="wh-popup-wraper" @touchmove.stop>
    <transition name="wh-popup">
      <div class="wh-popup-box" :style="{ height: '100%' }" v-if="show">
        <div class="search-box">
          <div class="search-header">
            <div class="search-header_input">
              <search-input v-model="queryName" @input="searchItems" placeholder="搜索名称"></search-input>
            </div>
            <div class="search-header_btn" @click="onClose">关闭</div>
          </div>
          <div class="search-body">
            <div class="search-result">
              <menu-item-box :item="item" v-for="(item, itemIndex) in queryList" :key="itemIndex" @onDetail="showItemDetailPopup"></menu-item-box>
            </div>
            <div class="wh-empty-box" v-if="!queryList || queryList.length === 0">
              <img class="wh-empty-img" src="@/assets/images/empty-3.png">
              <p class="wh-empty-text" v-if="!queryName">暂无搜索内容</p>
              <p class="wh-empty-text" v-else>没有找到，请换个关键词试试</p>
            </div>
          </div>
        </div>
        <!-- 购物车底部栏 -->
        <menu-cart-footer></menu-cart-footer>
        <!-- 商品详情弹窗 -->
        <menu-item-popup :show="itemDetailPopupShow" :item="selectedItem" @onClose="itemDetailPopupShow = false"></menu-item-popup>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SearchInput from '@/components/common/SearchInput'
import MenuItemBox from './MenuItemBox'
import MenuItemPopup from './MenuItemPopup'
import MenuCartFooter from './MenuCartFooter'
export default {
  name: 'menu-search-popup',
  components: { SearchInput, MenuItemBox, MenuItemPopup, MenuCartFooter },
  props: {
    show: { type: Boolean, required: true, default: false }
  },
  data () {
    return {
      queryName: '',
      queryList: [],
      itemDetailPopupShow: false,
      selectedItem: {}
    }
  },
  computed: {
    ...mapState('order', ['menuItems'])
  },
  watch: {
    menuItems(val, oldVal) {
      this.searchItems()
    }
  },
  methods: {
    searchItems () {
      if (!this.queryName) {
        this.queryList = []
        return
      }
      this.queryList = this.menuItems
        .reduce((result, item) => {
          return result.concat(item.items)
        }, [])
        .filter((x) => x.itemName.indexOf(this.queryName) > -1)
    },
    showItemDetailPopup (item) {
      this.selectedItem = item
      this.itemDetailPopupShow = true
    },
    onClose (e) {
      this.queryName = ''
      this.queryList = []
      this.$emit('onClose', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  z-index: 2001;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateX(-100%);
}

.search-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
  .search-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 10px 20px;
    align-items: center;
    background-color: $color-white;
    border-bottom: 1px solid $color-border;
    z-index: 50;
    .search-header_input {
      flex: 1;
    }
    .search-header_btn {
      flex: 0 0 auto;
      padding-left: 12px;
      color: $color-primary;
    }
  }
  .search-body {
    position: relative;
    width: 100%;
    padding-top: 124px ;
    padding-bottom: 120px;
  }
}

.search-result {
  width: 100%;
}
</style>
