<!--
 * @Author: hwu
 * @Date: 2020-11-11 13:32:31
 * @Description: 菜谱页面 -> 商品列表 -> 单个商品展示组件
 * @LastEditTime: 2023-08-15 17:59:21
-->

<template>
  <div :class="isSmallImageMode ? 'single-item-box' : 'big-image-item-box'" @click="showDetail()">
    <template v-if="isSmallImageMode">
      <div class="single-item-left">
        <img class="item-image" :src="transferItemImage(item.image)"/>
        <div class="suggest-tag" v-if="item.suggestTag">
          <i class="suggest-tag_icon iconfont iconshenqingkaitong"></i>
          <span class="suggest-tag_text">{{ item.suggestTag }}</span>
        </div>
      </div>
      <div class="single-item-right">
        <div class="single-item-right-top">
          <!-- 商品名称 -->
          <div class="item-name-row">
            <span class="item-name">{{ item.itemName }}</span>
            <img class="item-name_icon" :src="productTagIconUrl" v-if="productTagIconUrl"/>
          </div>
          <div class="item-maidian-row" v-if="item.sellingPoints && item.sellingPoints.length > 0">
            <span :class="['item-maidian', getSellPointsType(pointItem)]" :style="pointItem.styleData"
                  v-for="(pointItem, pointIndex) in item.sellingPoints" :key="pointIndex">{{ pointItem.text }}</span>
          </div>
          <!-- 商品描述 -->
          <div class="item-desc-row" v-if="item.sellingPoint">
            <span class="item-desc" v-if="item.sellingPoint">{{ item.sellingPoint }}</span>
          </div>
        </div>
        <div class="single-item-right-bottom">
          <div class="item-tag-row" v-if="item.promoTags && item.promoTags.length > 0">
            <span class="item-tag" v-for="(tag, tagIndex) in item.promoTags" :key="tagIndex">{{ tag.name }}</span>
          </div>
          <div class="item-price-row">
            <div class="item-price">
              <!-- 活动价 -->
              <span v-if="item.isMoreSpec === 0 && item.promoPrice">{{ item.promoPrice | priceFilter }}</span>
              <span v-else>{{ item.price | priceFilter }}</span>
              <!-- 多规格口味 或者非固定金额套餐，展示“起” -->
              <span class="item-price_desc"
                    v-if="item.isMoreSpec === 1 || (item.unitType === 1 && item.mapType === 0 && item.fixedUnit !== 1)">起</span>
              <!-- 称重商品展示重量 -->
              <span class="item-price_desc" v-if="item.weighingStatus === 1">/{{ item.unitCount }}{{
                  item.unitName
                }}</span>
              <!-- 活动商品，展示原价 -->
              <del class="item-price_origin" v-if="item.isMoreSpec === 0 && item.promoPrice">￥{{ item.price }}</del>
              <!-- 不是员工价，但是套餐的话 -->
              <template v-else-if="item.unitType === 1">
                <!-- 非固定金额套餐，展示最低折扣 -->
                <span class="item-price_rate" v-if="item.mapType === 0 && item.fixedUnit !== 1">
                <template v-if="item.lowestDiscountRate">{{ item.lowestDiscountRate }}折起</template>
              </span>
                <!-- 固定金额套餐，展示套餐原价 -->
                <del class="item-price_origin" v-else-if="item.originalPrice > item.price"> ￥{{
                    item.originalPrice
                  }}
                </del>
              </template>
            </div>
            <div class="item-remark" v-if="item.weighingStatus === 1 && item.ownTakeStatus !== 0">请至柜台选购</div>
            <div class="item-remark" v-else-if="item.saleStatus !== 1">已售完</div>
            <div class="item-btn-box" v-else>
              <template v-if="item.weighingStatus === 1 && item.ownTakeStatus === 0 && item.sailWeight && !item.weight">
                <div class="item-sailWeight" @click.stop="addItemQty($event, 'first')">{{
                    item.sailWeight
                  }}{{ item.unitName }}起售
                </div>
              </template>
              <template v-else>
              <span class="item-btn minus-btn" v-show="item.quantity > 0" @click.stop="minusItemQty()">
                <i class="iconfont iconjianshao"></i>
              </span>
                <template v-if="item.weighingStatus === 1 && item.ownTakeStatus === 0 && !item.maxWeight">
                  <span class="item-qty" v-if="item.weight > 0">{{ item.weight }}{{ item.unitName }}</span>
                </template>
                <template v-else>
                  <span class="item-qty" v-if="item.quantity > 0">{{ item.quantity }}</span>
                </template>
                <span class="item-btn add-btn" @click.stop="addItemQty($event)">
                <i class="iconfont iconzengjia-tianchong"></i>
              </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!--    大图模式-->
    <template v-else>
      <div class="big-item-top">
        <img class="item-image" :src="transferItemImage(item.bigImage, 'bigImage')"/>
        <div class="suggest-tag" v-if="item.suggestTag">
          <i class="suggest-tag_icon iconfont iconshenqingkaitong"></i>
          <span class="suggest-tag_text">{{ item.suggestTag }}</span>
        </div>
      </div>
      <div class="big-item-bottom">
        <div class="item-name-row">
          <span class="item-name">{{ item.itemName }}</span>
          <img class="item-name_icon" :src="productTagIconUrl" v-if="productTagIconUrl"/>
        </div>
        <div class="item-maidian-row" v-if="item.sellingPoints && item.sellingPoints.length > 0">
            <span :class="['item-maidian', getSellPointsType(pointItem)]" :style="pointItem.styleData"
                  v-for="(pointItem, pointIndex) in item.sellingPoints" :key="pointIndex">{{ pointItem.text }}</span>
        </div>
        <!-- 商品描述 -->
        <div class="item-desc-row" v-if="item.sellingPoint">
          <span class="item-desc" v-if="item.sellingPoint">{{ item.sellingPoint }}</span>
        </div>
        <div class="big-item-bottom-row">
          <div class="big-item-bottom-row-left">
            <div class="item-price-row">
              <div class="item-price">
                <!-- 活动价 -->
                <span v-if="item.isMoreSpec === 0 && item.promoPrice">{{ item.promoPrice | priceFilter }}</span>
                <span v-else>{{ item.price | priceFilter }}</span>
                <!-- 多规格口味 或者非固定金额套餐，展示“起” -->
                <span class="item-price_desc"
                      v-if="item.isMoreSpec === 1 || (item.unitType === 1 && item.mapType === 0 && item.fixedUnit !== 1)">起</span>
                <!-- 称重商品展示重量 -->
                <span class="item-price_desc" v-if="item.weighingStatus === 1">/{{ item.unitCount }}{{
                    item.unitName
                  }}</span>
                <!-- 活动商品，展示原价 -->
                <del class="item-price_origin" v-if="item.isMoreSpec === 0 && item.promoPrice">￥{{ item.price }}</del>
                <!-- 不是员工价，但是套餐的话 -->
                <template v-else-if="item.unitType === 1">
                  <!-- 非固定金额套餐，展示最低折扣 -->
                  <span class="item-price_rate" v-if="item.mapType === 0 && item.fixedUnit !== 1">
                <template v-if="item.lowestDiscountRate">{{ item.lowestDiscountRate }}折起</template>
              </span>
                  <!-- 固定金额套餐，展示套餐原价 -->
                  <del class="item-price_origin" v-else-if="item.originalPrice > item.price"> ￥{{
                      item.originalPrice
                    }}
                  </del>
                </template>
              </div>
            </div>
          </div>
          <div class="big-item-bottom-row-right">
            <div class="item-remark" v-if="item.weighingStatus === 1 && item.ownTakeStatus !== 0">请至柜台选购</div>
            <div class="item-remark" v-else-if="item.saleStatus !== 1">已售完</div>
            <div class="item-btn-box" v-else>
              <template v-if="item.weighingStatus === 1 && item.ownTakeStatus === 0 && item.sailWeight && !item.weight">
                <div class="item-sailWeight" @click.stop="addItemQty($event, 'first')">{{
                    item.sailWeight
                  }}{{ item.unitName }}起售
                </div>
              </template>
              <template v-else>
              <span class="item-btn minus-btn" v-show="item.quantity > 0" @click.stop="minusItemQty()">
                <i class="iconfont iconjianshao"></i>
              </span>
                <template v-if="item.weighingStatus === 1 && item.ownTakeStatus === 0 && !item.maxWeight">
                  <span class="item-qty" v-if="item.weight > 0">{{ item.weight }}{{ item.unitName }}</span>
                </template>
                <template v-else>
                  <span class="item-qty" v-if="item.quantity > 0">{{ item.quantity }}</span>
                </template>
                <span class="item-btn add-btn" @click.stop="addItemQty($event)">
                <i class="iconfont iconzengjia-tianchong"></i>
              </span>
              </template>
            </div>
          </div>
        </div>
        <div class="item-tag-row" v-if="item.promoTags && item.promoTags.length > 0">
          <span class="item-tag" v-for="(tag, tagIndex) in item.promoTags" :key="tagIndex">{{ tag.name }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'menu-item-box',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    detailShow: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      submitDisabled: false,
      productTagOptions: [
        {
          label: '微辣',
          icon: require('@/assets/images/item-icon_pepper1.png')
        },
        {
          label: '中辣',
          icon: require('@/assets/images/item-icon_pepper2.png')
        },
        {
          label: '重辣',
          icon: require('@/assets/images/item-icon_pepper3.png')
        },
        {
          label: '冷',
          icon: require('@/assets/images/item-icon_cold.png')
        },
        {
          label: '热',
          icon: require('@/assets/images/item-icon_hot.png')
        }
      ]
    }
  },
  filters: {
    priceFilter(val) {
      if (val === 0) {
        return '免费'
      }
      return '￥' + val
    }
  },
  computed: {
    ...mapState('order', ['balls', 'dropBalls']),
    isSmallImageMode() {
      // imageType:1小图 2大图，默认1
      return this.item.imageType !== 2
    },
    productTagIconUrl() {
      if (!this.item.productTag) {
        return ''
      }
      const productTag = this.productTagOptions.find((x) => x.label === this.item.productTag)
      if (!productTag) {
        return ''
      }
      return productTag.icon
    }
  },
  methods: {
    ...mapActions('order', ['minusItemQtyVuex', 'addItemQtyVuex', 'toggleShowBallVuex', 'addDropBallVuex']),
    transferItemImage(key, type) {
      if (!key) {
        if (type === 'bigImage') {
          return require('@/assets/images/item-default-big-image.png')
        }
        return require('@/assets/images/item-default.png')
      }
      return process.env.VUE_APP_QNY_LOAD_URL + key + '?imageView2/0/w/400'
    },
    showDetail() {
      if (!this.detailShow) {
        return false
      }
      if (this.item.weighingStatus === 1 && this.item.ownTakeStatus !== 0) {
        return false
      }
      if (this.item.saleStatus !== 1) {
        return false
      }
      this.$emit('onDetail', this.item, true)
    },
    minusItemQty() {
      if (this.submitDisabled) {
        return false
      }
      if (this.item.quantity > 1 && this.item.fixed === 0) {
        this.$toast('多规格商品,请至购物车中删减商品')
        return
      }
      this.submitDisabled = true
      this.minusItemQtyVuex(this.item.itemId)
        .then(() => {
          this.submitDisabled = false
        })
        .catch(() => {
          this.submitDisabled = false
        })
    },
    addItemQty(event, type) {
      if (this.submitDisabled) {
        return false
      }
      if (this.item.fixed === 0) {
        this.$emit('onDetail', this.item, false)
        return
      }
      const itemData = {
        itemId: this.item.itemId,
        make: this.item.make,
        taste: this.item.taste,
        quantity: 1
      }
      const formData = {
        parentId: this.item.parentId,
        items: [itemData]
      }
      this.submitDisabled = true
      this.addItemQtyVuex(formData)
        .then((res) => {
          if (res) {
            // type === 'first'时，即：显示“XX起售”，点击之后，v-if切换，节点消失了，找不到位置，所以取消动画
            if (type === 'first') {
            } else {
              this.showBall(event)
            }
          }
          this.submitDisabled = false
        })
        .catch(() => {
          this.submitDisabled = false
        })
    },
    showBall(event) {
      for (let i = 0; i < this.balls.length; i++) {
        if (!this.balls[i].show) {
          this.toggleShowBallVuex({ index: i, isShow: true, el: event.target })
          this.addDropBallVuex({ ball: this.balls[i] })
        }
      }
    },
    // 卖点对应的class
    getSellPointsType(pointItem) {
      // 1：文字形式
      // 2：线框
      // 3：浅色块
      // 4：深色块
      if (pointItem.type === 1) {
        return 'type-text'
      } else if (pointItem.type === 2) {
        return 'type-line'
      } else if (pointItem.type === 3) {
        return 'type-light'
      } else {
        return 'type-deep'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.single-item-box {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 24px 24px;
  align-items: flex-start;
  text-align: left;

  .single-item-left {
    position: relative;
    flex: 0 0 180px;
    margin-right: 20px;

    img {
      width: 100%;
      border-radius: 4px;
    }
  }

  .single-item-right {
    flex: 1;
    min-height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item-name-row {
      margin-bottom: 8px;
    }

    .item-tag-row {
      margin-bottom: 8px;
    }
  }
}

.big-image-item-box {
  width: 100%;
  padding: 0 24px 24px;

  .big-item-top {
    position: relative;
    margin-top: 8px;

    .item-image {
      width: 100%;
      height: 336px;
      border-radius: 4px;
    }
  }

  .big-item-bottom {
    margin-top: 8px;
    //display: flex;

    //.big-item-bottom-left {
    //  width: 180px;
    //}
    //
    //.big-item-bottom-right {
    //  flex: 1;
    //  display: flex;
    //  justify-content: space-between;
    //  padding-left: 20px;
    //
    //  .big-item-bottom-right-content {
    //    .item-tag-row {
    //      margin-top: 8px;
    //    }
    //  }
    //}
    .big-item-bottom-row {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
    .item-tag-row {
      margin-top: 8px;
    }
  }
}

.suggest-tag {
  position: absolute;
  display: flex;
  top: -19px;
  height: 44px;
  // line-height: 40px;
  padding: 1px;
  flex-wrap: nowrap;
  align-content: center;
  background-color: $color-danger;
  font-size: 24px;
  border-radius: 6px;
  z-index: 5;
  transform: scale(0.66);
  transform-origin: center left;

  .suggest-tag_icon {
    flex: 0;
    height: 42px;
    line-height: 42px;
    margin: 0 6px;
    color: $color-white;
    font-size: 24px;
  }

  .suggest-tag_text {
    flex: 1;
    display: inline-block;
    padding: 0 6px;
    height: 40px;
    line-height: 40px;
    color: $color-danger;
    background-color: $color-white;
    border-radius: 0 6px 6px 0;
    white-space: nowrap;
  }
}

.item-name-row {
  display: -webkit-box;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  .item-name {
    line-height: 36px;
    color: $color-text-main;
    font-size: 30px;
    font-weight: bold;
  }

  .item-name_icon {
    width: auto;
    height: 36px;
    margin-left: 10px;
    vertical-align: sub;
  }
}

.item-price-row {
  display: flex;
  width: 100%;
  align-items: center;

  .item-price {
    flex: 1;
    color: $color-danger;
    font-size: 30px;
    white-space: nowrap;

    .item-price_desc {
      font-size: 24px;
      color: $color-text-sub;
    }

    .item-price_rate {
      display: inline-block;
      margin-left: 16px;
      font-size: 24px;
      color: $color-text-sub;
    }

    .item-price_origin {
      margin-left: 16px;
      font-size: 24px;
      color: $color-text-sub;
    }
  }
}

.item-tag-row {
  width: 100%;
  min-height: 44px;

  .item-tag {
    display: inline-block;
    padding: 2px 4px;
    font-size: 24px;
    color: $color-warning;
    border: 1px solid $color-warning;
    border-radius: 6px;
    transform: scale(0.76);
    transform-origin: center left;
  }
}

.item-remark {
  flex: 0;
  font-size: 24px;
  color: $color-text-sub;
  white-space: nowrap;
}

.item-btn-box {
  flex: 0;
  display: flex;
  align-items: center;

  .item-btn {
    font-size: 0;

    i {
      color: $color-primary;
      font-size: 48px;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .item-qty {
    display: inline-block;
    min-width: 60px;
    white-space: nowrap;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    color: $color-text-main;
  }

  .item-sailWeight {
    display: inline-block;
    padding: 2px 8px;
    font-size: 24px;
    color: $color-primary;
    white-space: nowrap;
    border: 1px solid $color-primary;
    border-radius: 28px;
  }
}
</style>
